$main-gray-color: #475467;
$semi-dark-color: #667085;
$blue-color: #3538CD;
$main-dark-color: #101828;
$light-background-color: #f9fafb;


$font-sm: 12px;
$font-md: 14px;
$font-lg: 16px;
$font-xl: 18px;
$font-xxl: 20px;

@media (max-width: 800px) {
    $font-sm: 10px;
    $font-md: 12px;
    $font-lg: 14px;
    $font-xl: 16px;
    $font-xxl: 18px;
}