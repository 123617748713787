@import "bootstrap-variables.scss";
@import "variables.scss";
@import "responsiveTable.scss";
//@import "~bootstrap/scss/bootstrap";

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
  outline: none;
  background: $primary;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
  outline: none;
}

.form-control:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #cacaca;
}

.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.px-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.px-70 {
  padding-left: 70px;
  padding-right: 70px;
}

.px-80 {
  padding-left: 80px;
  padding-right: 80px;
}

.px-90 {
  padding-left: 90px;
  padding-right: 90px;
}

.px-100 {
  padding-left: 100px;
  padding-right: 100px;
}

.container-1400 {
  max-width: 1400px !important;
}

.container-900 {
  max-width: 900px !important;
}

.container-800 {
  max-width: 800px !important;
}

.bold {
  font-weight: 550 !important;
}

.text-shadow {
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-450 {
  font-weight: 450;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-550 {
  font-weight: 550;
}

.font-weight-600 {
  font-weight: 600;
}

/*Buttons*/
.search-container {
  max-width: 600px;
}

.section-heading {
  color: $main-dark-color;
  font-size: 24px;
  font-weight: 700;
}

.plan-card {
  border-radius: 16px;
  border: 1px solid var(--gray-200, #eaecf0);
  //border: 2px solid var(--primary-600, #444CE7);
  background: var(--base-white, #fff);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08); // Enhanced shadow
  padding: 20px;
  cursor: pointer;
}

.plan-card-option {
  border: 1px solid var(--gray-200, #eaecf0);
  border-radius: 12px;
  padding: 8px;
  cursor: pointer;
  min-width: 350px;
}

.plan-title-option {
  font-size: 16px;
  font-weight: 500;
  color: $main-dark-color;
}

.plan-selected {
  border: 2px solid var(--primary-600, #444ce7);
}

.plan-title {
  color: $main-dark-color;
  font-size: 26px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.72px;
}

.plan-subtitle {
  color: $main-dark-color;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.plan-feature-text {
  color: $main-gray-color;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.red-button {
  background-color: #d92d20;
  border: 1px solid #d92d20;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 12px !important;
}

.red-button:hover {
  background-color: #d37973;
  border: 1px solid #d37973;
  color: white;
}

.light-red {
  color: white !important;
  background-color: #fa6359;
  border: 1px solid #fa6359;
}

.light-red:hover {
  background-color: #ff9d96 !important;
  border: 1px solid #ff9d96 !important;
}

.spinner {
  width: 30px;
  height: 30px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #007bff;
  animation: spin 1s ease-in-out infinite;
}

.text-container {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.text-content {
  max-height: 48px;
  overflow: hidden;
}

.custom-bckgr {
  background-color: #fffefb;
}

.absolute-training-position {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.video-text-content {
  max-height: 220px;
  overflow: hidden;
}

.expanded .text-content,
.expanded .video-text-content {
  max-height: none;
}

.expand-button {
  display: block;
  padding: 0 !important;
  width: 40px;
  background-color: transparent !important;
  border: none !important;
  text-align: center;
  cursor: pointer;
}

.expand-button span {
  font-size: 14px;
}

.order-icon {
  width: 16px !important;
  height: 16px !important;
  cursor: pointer;
}

.hide-spinner {
  border: transparent !important;
}

.hide-spinner-2 {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.transparent-button {
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 8px;
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 12px !important;
}

.transparent-button:hover {
  background-color: #eeecec;
}

.light-blue-button {
  background-color: #eef4ff;
  border: 1px solid #eef4ff;
  border-radius: 8px;
  color: #3538cd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 12px !important;
}

.light-blue-button:hover {
  background-color: #d8e3f8;
  border: 1px solid #d8e3f8;
  color: #040694;
}

.main-button {
  background-color: #444ce7;
  border: 1px solid #444ce7;
  border-radius: 8px;
  color: white;
  padding: 6px 12px !important;
}

.main-button:hover {
  background-color: #8187e9;
  border: 1px solid #8187e9;
  color: white;
}

.upgrade-button {
  cursor: pointer;
  background-color: #444ce7;
  border: 1px solid #444ce7;
  border-radius: 8px;
  color: white;
  padding: 6px 12px !important;
}

.upgrade-button:hover {
  background-color: #7d82e2;
  border: 1px solid #7d82e2;
  color: white;
}

.premium-button {
  background-color: #e7b344;
  background-color: #e7b344;
  border-radius: 8px;
  color: white;
  padding: 6px 12px !important;
}

.search-button {
  background-color: #133453;
  border: 1px solid #133453;
  border-radius: 2.625rem;
  color: white;
  padding: 7px 14px !important;
  font-size: 14px;
}

.search-button:hover {
  color: white !important;
  background-color: #133453;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
}

.search-options-container {
  border-radius: 6px;
  border: 1px solid #d0d5dd !important;
  overflow: hidden;
}

.search-options-container> :not(:last-child) {
  border-right: 1px solid #d0d5dd !important;
}

.search-option-button {
  background-color: #fff;
  border: 0 !important;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 7px 16px;
  text-decoration: none;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  color: $main-gray-color;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
}

.search-option-button:hover:not(:disabled) {
  background-color: #e6e6e6;
}

.search-option-button:disabled:hover {
  background-color: #fff !important;
}

.search-option-button:focus {
  outline: 0px;
}

.clickable-link {
  color: rgb(51, 154, 238);
  cursor: pointer;
}

.clickable-link:hover {
  text-decoration: underline;
}

.fit-button {
  height: fit-content;
  white-space: nowrap;
}

.thumbnail {
  display: inline-block;
  overflow: hidden;
}

.thumbnail img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.crop {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.blur {
  filter: blur(15px) !important;
  transition: filter 0.3s ease-in-out !important;
  -webkit-filter: blur(15px);
  -webkit-transition: -webkit-filter 0.3s ease-in-out;
}

.opacity-low {
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
}

.hidden {
  visibility: hidden;
}

.no-scroll {
  overflow: hidden;
}

.emphasize {
  font-weight: 600;
}

// Toggle button styles

.switch {
  position: relative;
  display: inline-block;
  min-width: 50px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.rounded-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #133453;
  color: white;
  font-size: 22px;
}

.icon-letter {
  font-weight: bold;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #444ce7;
}

input:focus+.slider {
  box-shadow: 0 0 1px #444ce7;
}

input:checked+.slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 25px;
}

.slider.round:before {
  border-radius: 50%;
}

.text-desc-2 {
  color: $main-gray-color;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
}

.text-desc-3 {
  color: $main-gray-color;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
}

.info-text-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  color: $main-dark-color;
  text-align: center;
  margin-bottom: 10px;
}

.info-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: $main-gray-color;
}

.list-option {
  cursor: pointer;
}

.list-option:hover {
  background: #e2e2e2;
  transition: background 0.1s ease-in-out;
}

.iconSearchContainer {
  display: flex;
  align-items: center;
  opacity: 0.6;
}

.min-container {
  min-width: 500px;
}

.search-icon {
  background: #fff;
  border: 1px solid #ced4da;
  border-right: 0 !important;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; //ovooo
  color: #667085;
  height: 50px;
  width: 40px;
  justify-content: center;
  //cursor: pointer;
}

.input-field {
  border-left: 0 !important;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  height: 50px;
  min-width: 100px;
  font-size: 15px;
}

.input-field-custom {
  border-left: 0 !important;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  height: 50px;
  min-width: 100px;
  font-size: 15px;
}

.tag {
  border-radius: 13px;
  padding: 1px 7px;
  //white-space: nowrap;
}

.tag1 {
  color: rgb(8, 156, 53);
  border: 2px solid rgb(8, 156, 53);
}

.tag2 {
  color: rgb(51, 154, 238);
  //border: 2px solid rgb(51, 154, 238);
}

.fs-14 {
  font-size: 14px;
}

.input-field1 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 !important;
}

.search-btn-container {
  border: 1px solid #ced4da;
  border-left: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.border-right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-right: 1px solid #ced4da !important;
}

.no-border {
  border: 0 !important;
}

.optional-position {
  position: absolute;
  width: 100%;
}

/*Settings styles*/
.settings-title {
  color: $main-dark-color;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  margin-top: 60px;
  padding-left: 32px;
}

.tabs {
  margin-top: 20px;
  margin-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
}

.tab {
  color: $semi-dark-color;
  font-size: 14px;
  //border-bottom: 1px solid #eaeaea;
  font-weight: 600;
  cursor: pointer;
}

.tab.selected {
  color: $blue-color;
  border-bottom: 2px solid $blue-color;
}

.section-underline {
  border-bottom: 1px solid #eaeaea;
  width: 100%;
}

.account-section-title {
  font-size: 18px;
  color: $main-dark-color;
  font-weight: 600;
  line-height: 28px;
}

.inline-block {
  display: inline-block !important;
}

.section-desc {
  color: $main-gray-color;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.link-text {
  color: #007bff;
  cursor: pointer;
}

.link-text:hover {
  text-decoration: underline;
}

.field-label {
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.newsletters-headers {
  color: #344054;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.email-container {
  border: 1px solid #ced4da;
  border-radius: 4px;
  background: white;
}

.see-more-button {
  font-size: 22px;
  height: 22px;
  cursor: pointer;
  // animation: blink 1.5s infinite ease-in-out;
}

.display-toggle {
  display: none;
}

.delete-desc {
  color: $main-gray-color;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.b-700 {
  font-weight: 700;
}

.table-section {
  border: 1px solid #eaecf0;
  border-radius: 12px;
  //padding: 20px 16px;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.06), 0 1px 3px rgba(16, 24, 40, 0.1);
}

.status-container {
  width: fit-content;
  padding: 1px 3px;
  border-radius: 16px;
}

.paid-status {
  color: #027a48;
  border: 2px solid #027a48;
}

.disabled {
  pointer-events: none;
  background-color: #eaeaea;
}

.category {
  //border: 2px solid #444ce7;
  font-weight: 550 !important;
  border-radius: 16px;
  padding: 0px 8px;
  color: #444ce7;
  //text-wrap: nowrap;
  height: fit-content;
  //width: min-content;
}

.break-word {
  word-break: break-all;
}

.filters-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.custom-btn {
  border-radius: 8px;
  text-wrap: nowrap;
  height: fit-content;
}

.custom-btn1 {
  background: #444ce7;
  color: white;
  font-weight: 400;
}

.custom-btn1:hover {
  background-color: #8187e9 !important;
  color: white !important;
}

.custom-btn2 {
  background: #0077b5;
  color: white;
  font-weight: 400;
}

.custom-btn2:hover {
  background-color: #6ea5c4 !important;
  color: white !important;
}

.icon {
  width: 22px !important;
  height: 22px !important;
  min-width: 22px !important;
  min-height: 22px !important;
}

.regular-fs {
  font-size: 14px !important;
}

.sub-title5 {
  font-size: 20px;
  color: $main-dark-color;
}

.like-icon {
  font-size: 35px;
  cursor: pointer;
  color: rgb(75, 75, 75);
}

.card-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $main-gray-color;
}

.card-title1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $main-dark-color;
}

.item-container {
  background-color: white;
  position: relative;
  border: 1px solid #eaeaea;
  padding: 60px 20px 20px 20px;
  border-radius: 12px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.06), 0 1px 3px rgba(16, 24, 40, 0.1);
}

.buttons-container {
  position: absolute;
  top: 15px;
  right: 10px;
}

.category-container {
  position: absolute;
  top: 15px;
  left: 25px;
}

.video-skeleton {
  width: 100%;
  aspect-ratio: 16 / 9;
}

@keyframes blink {
  0% {
    color: black;
  }

  50% {
    color: rgb(197, 111, 53);
  }

  100% {
    color: black;
  }
}

/**/

@media only screen and (max-width: 767px) {
  .info-text-title {
    font-size: 20px;
    line-height: 25px;
  }

  .text-desc-2 {
    font-size: 14px;
  }

  .min-container {
    min-width: 0px;
  }

  .section-heading {
    font-size: 22px;
  }
}

@media only screen and (max-width: 576px) {
  .info-text-title {
    font-size: 18px;
    line-height: 18px;
  }

  .card-text,
  .card-title1 {
    font-size: 12px;
  }

  .item-container {
    padding: 40px 12px 12px 12px;
  }

  .like-icon {
    font-size: 20px;
  }

  .info-text {
    font-size: 14px;
  }

  .displaying-text {
    display: none;
  }

  .section-heading {
    font-size: 20px;
  }

  .input-field {
    font-size: 13px !important;
    height: 40px !important;
  }

  .regular-fs {
    font-size: 13px !important;
  }

  .main-button {
    font-size: 13px;
  }

  .search-icon {
    height: 40px;
  }

  .search-button {
    padding: 5px 12px !important;
    font-size: 13px;
  }
}