@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "variables.scss";

.mdc-dialog__surface {
  border-radius: 8px !important;
}

.mat-mdc-dialog-content {
  max-height: 99vh !important;
}

.mdc-text-field--outlined .mdc-notched-outline {
  height: 38px !important;
}

.mat-mdc-form-field-infix {
  min-height: 38px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.mdc-text-field--outlined .mdc-text-field__input {
  height: 38px !important;
}

.mat-mdc-form-field-icon-suffix > .mat-icon {
  padding: 7px !important;
  padding-right: 9px !important;
}

.mat-mdc-form-field {
  width: 100%;
}

.mat-mdc-standard-chip .mat-mdc-chip-trailing-icon {
  display: flex;
  align-items: center;
}

.mat-mdc-select-value {
  font-size: 14px;
  letter-spacing: normal;
}

.mat-mdc-form-field {
  background: white;
}

.mat-mdc-option {
  font-size: 14px !important;
  min-height: 38px !important;
  font-family: "Inter";
}

.mat-select-search-input {
  font-family: "Inter" !important;
}

.mat-mdc-option.mdc-list-item {
  font-family: "Inter" !important;
}

// .mdc-notched-outline__notch,

.mdc-notched-outline__leading {
  border: 1px solid #ced4da !important;
  border-right: 0px !important;
}

.mdc-notched-outline__trailing {
  border: 1px solid #ced4da !important;
  border-left: 0px !important;
}

.mat-mdc-form-field-subscript-wrapper {
  height: 0px;
}

.mdc-text-field .mdc-floating-label {
  top: 50% !important;
}

// .mat-mdc-snack-bar-container {
//     background-color: #133453 !important;
// }
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #133453 !important;
}

// .snackbar-style {
//     background-color: #133453 !important;
//     color: white !important;
// }

.mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: white !important;
}

.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: translateY(0px) !important;
}

.mat-mdc-chip-action-label {
  display: flex;
  align-items: center;
}

.mat-mdc-standard-chip {
  border-radius: 6px !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: white !important;
  border: 1px solid #d0d5dd;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
  .mdc-evolution-chip__text-label {
  color: $main-gray-color !important;
  font-family: "Inter" !important;
}

.mat-chip-item {
  width: 20px;
  min-width: 20px;
}

.mat-chip-listbox .mat-chip.mat-accent {
  background-color: transparent;
  /* Set the background color to transparent */
  color: initial;
  /* Use the initial color */
  box-shadow: none;
  /* Remove the box-shadow */
}

.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  font-family: Inter !important;
  font-size: 14px !important;
}

.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
  display: none !important;
}

.mdc-checkbox__background {
  width: 16px !important;
  height: 16px !important;
}

.mat-mdc-menu-panel {
  // min-width: 300px !important;
  max-width: 500px !important;
}

.mat-mdc-menu-item-text {
  display: flex;
  align-items: center;
}

.mdc-dialog .mdc-dialog__content {
  padding: 5px !important;
}

.p-dropdown-panel {
  width: 100%;
}

.p-multiselect-panel {
  width: 100%;
}

.p-inputtext {
  padding: 0.5rem 0.75rem;
}

.p-overlay {
  width: 100% !important;
}

.p-dropdown-panel,
.p-multiselect-panel,
.p-inputtext::placeholder {
  font-size: 14px !important;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Inter";
}

.p-multiselect .p-multiselect-label.p-placeholder {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Inter";
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  font-size: 14px;
}

.p-checkbox .p-checkbox-box {
  height: 18px;
  width: 18px;
  margin-top: 2px;
  border-radius: 2px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: #0000000a;
}

.p-inputtext:not(.p-disabled):hover,
.p-inputtext:not(.p-disabled):focus,
.p-dropdown:not(.p-disabled):hover,
.p-treeselect:not(.p-disabled),
.p-inputwrapper:not(.p-disabled),
.p-dropdown:not(.p-disabled).p-focus,
.p-multiselect:not(.p-disabled):hover,
.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: #ced4da;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: #0000000a;
}

.p-multiselect-label,
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  font-size: 14px;
}

.p-component {
  font-size: 14px !important;
}
.p-treeselect {
  border-radius: 4px;
}
.p-treeselect .p-treeselect-label {
  padding: 7px 10px 8px 15px;
  color: rgba(0, 0, 0, 0.6);
  //color: rgba(0, 0, 0, 0.87);
}

.p-tree {
  padding: 4px;
}

.p-treeselect-panel .p-treeselect-header {
  padding: 8px 16px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  // background: rgba(0, 0, 0, 0.04);
  background: transparent;
  border: none;
  border-radius: 4px;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight:hover {
  background: rgba(0, 0, 0, 0.04);
  border: none;
  border-radius: 4px;
}

.p-tree .p-tree-container .p-treenode:focus > .p-treenode-content {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}

@media only screen and (max-width: 576px) {
  .mat-mdc-menu-content,
  .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    font-size: 13px !important;
  }

  .mat-mdc-select-value {
    font-size: 13px;
    letter-spacing: normal;
  }

  .mdc-evolution-chip__text-label {
    font-size: 13px !important;
  }
}
