@import "../styles/variables.scss";

.table-container {
  background-color: #fff;
  overflow: hidden;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.06), 0 1px 3px rgba(16, 24, 40, 0.1);
}

.pages-navigator {
  display: flex;
  height: 35px;
  gap: 15px;
}

.filters-div {
  display: flex;
  padding: 20px 16px;
}

.navigate-page {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigate-page:hover {
  background: #eaecf0;
  border-radius: 4px;
}

.pagination-div {
  display: flex;
  padding: 15px 16px;
}

.options-div {
  display: flex;
  flex-wrap: wrap;
}

.text-centered {
  text-align: center !important;
}

.no-bx {
  border-right: 0px !important;
  border-left: 0px !important;
}

.icon-button {
  padding: 2px;
}

.option-button {
  background-color: #fff;
  border: 1px solid #d0d5dd !important;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 7px 12px;
  text-decoration: none;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  color: $main-gray-color;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
}

.option-button:hover:not(:disabled) {
  background-color: #e6e6e6;
}

.option-button:disabled:hover {
  background-color: #fff !important;
}

.option-selected {
  background-color: #e6e6e6 !important;
}

.icon-container {
  background-color: #e0eaff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.icon-container-large {
  background-color: #e0eaff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.icon-container-vertical {
  background-color: #e0eaff;
  border-radius: 8px;
  border: 2px solid lightgray;
  display: flex;
  justify-content: center;
  width: 34px;
  height: 60px;
}

.icon-container-horizontal {
  background-color: #e0eaff;
  border-radius: 8px;
  border: 2px solid lightgray;
  display: flex;
  justify-content: center;
  width: 60px;
  height: 34px;
}

.size-vertical {
  width: 34px;
  height: 60px;
}

.size-horizontal {
  width: 60px;
  height: 34px;
}

.rating {
  border-radius: 16px;
  padding: 2px 0px;
  height: min-content;
  width: min-content;
}

.rating-up {
  color: #027a48;
  background: #ecfdf3;
}

.rating-down {
  color: #b42318;
  background-color: #fef3f2;
}

.order-icon {
  cursor: pointer;
}

.arrow {
  font-size: 18px;
  vertical-align: middle;
  cursor: pointer;
}

.low-opacity {
  opacity: 0.3;
}

.disabled-opacity {
  opacity: 0.6;
}

.tags {
  border-radius: 16px;
  font-size: 12px;
  white-space: nowrap;
}

.tags-shorts {
  color: #c01048;
  background-color: #fff1f3;
}

.tags-direct-response {
  color: #175cd3;
  background-color: #eff8ff;
}

.tags-instream {
  color: #b54708;
  background-color: #fffaeb;
}

.tags-NS {
  color: #344054;
  background-color: #f2f4f7;
}

.text-clipped {
  max-width: 150px;
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chart-option:first-child {
  border-bottom-left-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.chart-option:last-child {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.first-option {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.last-option {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.option-not-selected {
  //opacity: 0.7;
}

.company-name {
  font-weight: 600;
}

.company-domain {
  font-weight: 400;
}

.paginator-option {
  min-width: 35px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  height: 100%;
  align-items: center;
  cursor: pointer;
  margin: 2px 2px;
  font-size: 14px;
}

.paginator-option-selected {
  background: rgb(228, 228, 228);
}

.paginator-option:hover {
  background: rgb(228, 228, 228);
}

.option1 {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.option4 {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.url {
  color: #175cd3;
  font-style: italic;
  text-decoration: underline;
}

//Table--------------------------------------------
table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}

table tr {
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: left;
}

table th {
  white-space: nowrap;
  font-size: 0.85em;
  letter-spacing: 0.1em;
}

thead {
  background: #fcfcfd;
  border: 0 solid #eaecf0;
  border-bottom-width: 1px;
  border-top-width: 1px;
  height: 45px;
}

th {
  color: $main-gray-color;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 18px;
}

th[fixed],
td[fixed] {
  width: 60px;
}

td {
  color: $main-gray-color;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border: 0 solid #eaecf0;
  border-bottom-width: 1px;
}

td[dark] {
  color: $main-dark-color !important;
}

@media screen and (max-width: 870px) {
  .content-end {
    justify-content: flex-end;
    margin-right: 0px !important;
  }

  .text-centered {
    text-align: end !important;
  }

  .status-container {
    margin-left: auto;
  }

  .logo-display {
    display: flex !important;
    align-items: flex-end;
    justify-content: space-between;
  }

  .text-clipped {
    max-width: none;
  }

  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    display: block;
    //margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 20px;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

@media only screen and (max-width: 576px) {
  .options-div {
    width: 100%;
  }

  .option1 {
    border-bottom-left-radius: 0px;
  }

  .option2 {
    border-top-right-radius: 6px;
  }

  .option3 {
    border-bottom-left-radius: 6px;
  }

  .option4 {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 6px;
  }

  .option-button {
    font-size: 13px;
  }
}

@media screen and (max-width: 500px) {
  .paginator-option {
    min-width: 25px;
  }

  .option-navigator {
    padding: 8px 8px !important;
  }

  .pagination-div {
    padding: 10px 8px;
  }
}
